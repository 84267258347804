/* Beispiel Liste */
var defaultItems = [
  {
    id: 1,
    product: "Bananen",
    brand: "Chiquita",
    quantity: 3,
    unit: "Stück",
    category: "Fruits",
    emoji: "\u{1F34C}",
  },
  {
    id: 2,
    product: "Bier",
    brand: "Corona",
    quantity: 4,
    unit: "Flaschen",
    category: "Alkohol",
    emoji: "\u{1F37B}",
  },
  {
    id: 3,
    product: "Staudensellerie",
    brand: "REWE Bio",
    quantity: 2,
    unit: "Stück",
    category: "Gemüse",
    emoji: "\u{1F96C}",
  },
  {
    id: 4,
    product: "Avocado",
    brand: "Hass",
    quantity: 2,
    unit: "Stück",
    category: "Obst",
    emoji: "\u{1F951}",
  },
  {
    id: 5,
    product: "Milch",
    brand: "Müller",
    quantity: 1,
    unit: "Liter",
    category: "Milchprodukte",
    emoji: "\u{1F95B}",
  },
  {
    id: 6,
    product: "Hähnchenbrust",
    brand: "Gut&Günstig",
    quantity: 500,
    unit: "Gramm",
    category: "Fleisch",
    emoji: "\u{1F357}",
  },
  {
    id: 7,
    product: "Tomaten",
    brand: "Datterino",
    quantity: 6,
    unit: "Stück",
    category: "Gemüse",
    emoji: "\u{1F345}",
  },
  {
    id: 8,
    product: "Joghurt",
    brand: "Danone",
    quantity: 4,
    unit: "Becher",
    category: "Milchprodukte",
    emoji: "\u{1F366}",
  },
  {
    id: 9,
    product: "Vollkornbrot",
    brand: "Kamps",
    quantity: 1,
    unit: "Laib",
    category: "Brot",
    emoji: "\u{1F35E}",
  },
  {
    id: 10,
    product: "Erdbeeren",
    brand: "Driscoll's",
    quantity: 250,
    unit: "Gramm",
    category: "Obst",
    emoji: "\u{1F353}",
  },
  {
    id: 11,
    product: "Schokolade",
    brand: "Lindt",
    quantity: 1,
    unit: "Tafel",
    category: "Süßigkeiten",
    emoji: "\u{1F36B}",
  },
  {
    id: 12,
    product: "Eier",
    brand: "Freiland",
    quantity: 12,
    unit: "Stück",
    category: "Eier",
    emoji: "\u{1F95A}",
  },
  {
    id: 13,
    product: "Orangensaft",
    brand: "Tropicana",
    quantity: 1,
    unit: "Liter",
    category: "Getränke",
    emoji: "\u{1F34A}",
  },
  {
    id: 14,
    product: "Äpfel",
    brand: "Boskop",
    quantity: 6,
    unit: "Stück",
    category: "Obst",
    emoji: "🍏",
  },
  {
    id: 15,
    product: "Zwiebeln",
    brand: "Bio",
    quantity: 3,
    unit: "Stück",
    category: "Gemüse",
    emoji: "🧅",
  },
  {
    id: 16,
    product: "Käse",
    brand: "Gouda",
    quantity: 200,
    unit: "Gramm",
    category: "Milchprodukte",
    emoji: "🧀",
  },
  {
    id: 17,
    product: "Wasser",
    brand: "Evian",
    quantity: 2,
    unit: "Liter",
    category: "Getränke",
    emoji: "💧",
  },
  {
    id: 18,
    product: "Wein",
    brand: "Merlot",
    quantity: 1,
    unit: "Flasche",
    category: "Alkohol",
    emoji: "🍷",
  },
  {
    id: 19,
    product: "Brot",
    brand: "Dinkel",
    quantity: 1,
    unit: "Laib",
    category: "Brot",
    emoji: "🍞",
  },
  {
    id: 20,
    product: "Kaffee",
    brand: "Tchibo",
    quantity: 500,
    unit: "Gramm",
    category: "Getränke",
    emoji: "☕",
  },
  {
    id: 21,
    product: "Tee",
    brand: "Twinings",
    quantity: 1,
    unit: "Packung",
    category: "Getränke",
    emoji: "🍵",
  },
  {
    id: 22,
    product: "Paprika",
    brand: "Bio",
    quantity: 3,
    unit: "Stück",
    category: "Gemüse",
    emoji: "🫑",
  },
  {
    id: 23,
    product: "Reis",
    brand: "Basmati",
    quantity: 1,
    unit: "Kilogramm",
    category: "Getreide",
    emoji: "🍚",
  },
];

export var defaultItems;
