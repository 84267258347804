import supabase from "./config/supabaseClient";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import "./style.css";
import { defaultItems } from "./defaultList.js";
import { categoryList } from "./categoryList";
import { useEffect, useState } from "react";
import emojiButton from "./assets/emojiButton.svg";

const appTitle = "lazelist.io";

function App() {
  // 1. Define state variable
  const [showForm, setShowForm] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const [items, setItems] = useState(null);
  const [itemsUpdated, setItemsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("all");
  const categories = [
    "frequent",
    "people",
    "nature",
    "foods",
    "activity",
    "places",
    "objects",
    "symbols",
    "flags",
  ];

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(
    function () {
      async function getItems() {
        setIsLoading(true);

        let query = supabase.from("shoppingListTable").select(); // .from: Auswahl der Tabelle .select: Welche Daten gewählt werden sollen (klammer leer heißt alle Daten)

        if (currentCategory !== "all")
          query = query.eq("category", currentCategory); // eq = equal (zB nur eine bestimmte Kategorie anzeigen)

        const { data: items, error } = await query.order("category", {
          ascending: true,
        });
        // Wenn keine Daten gefetched werden können, dann wird diese Meldung ausgegeben
        if (!error) setItems(items);
        else alert("There was a problem getting");
        setIsLoading(false);
      }
      getItems();

      const channelB = supabase
        .channel("table-db-changes")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "shoppingListTable",
          },
          (payload) => {
            console.log(payload);
            getItems(); // Refetch items whenever a change occurs
          }
        )
        .subscribe();
    },
    [itemsUpdated] // [] sind hier ein dependency Array. Anderfalls würde bei jedem State Update die Daten neu gefetched werden
  );
  return (
    <>
      <Header
        showForm={showForm}
        setShowForm={setShowForm}
        setItemsUpdated={setItemsUpdated}
      />
      {/* 2. Use state variable */}
      {isLoading ? (
        <Loader />
      ) : (
        <List
          items={items}
          setItems={setItems}
          fetchError={fetchError}
          setFetchError={setFetchError}
          setItemsUpdated={setItemsUpdated}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
        />
      )}
      {/* <CategoryFilter setCurrentCategory={setCurrentCategory} /> */}
      <DeleteCheckedButton
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
        setItemsUpdated={setItemsUpdated}
      />
    </>
  );
}

function Header({ showForm, setShowForm, setItems, setItemsUpdated }) {
  return (
    <header>
      <div className="top-area">
        <h1>{appTitle}</h1>
        <button
          className="toggle-button"
          id="toggleButton"
          // 3. Update state variable
          onClick={() => setShowForm((show) => !show)}
        >
          {showForm ? "-" : "+"}
        </button>
      </div>
      <hr />
      {showForm ? (
        <NewItemForm
          setItems={setItems}
          setShowForm={setShowForm}
          setItemsUpdated={setItemsUpdated}
        />
      ) : null}
    </header>
  );
}

function NewItemForm({ setItemsUpdated }) {
  const [emoji, setEmoji] = useState("");
  const [product, setProduct] = useState("");
  const [brand, setBrand] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [category, setCategory] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  async function handleSubmit(e) {
    // 1. Prevent browser reload
    e.preventDefault();
    console.log(emoji, brand, product, quantity, unit, category);

    // 2. Check if data is valid. If so, create a new item
    if (emoji && product && brand && quantity && unit && category)
      console.log("There is data");

    // 3. Upload fact to Supabase and recieve the new Fact Object
    setIsUploading(true);
    const { data: newItem, error } = await supabase
      .from("shoppingListTable")
      .insert([{ product, brand, quantity, unit, category, emoji }])
      .select();
    setIsUploading(false);

    // 4. Reload the Data with the new items

    if (!error) {
      // Überprüfen Sie, ob es keinen Fehler beim Hinzufügen des neuen Artikels gibt
      setItemsUpdated((updated) => !updated); // Ändern Sie den Wert von itemsUpdated
    }

    // 5. Reset input fields
    setEmoji("");
    setProduct("");
    setBrand("");
    setQuantity("");
    setUnit("");
    setCategory("");

    // 6. Close the form
    // setShowForm(false);
  }

  return (
    <>
      <div>
        <form
          className="entry-form"
          id="newItemForm"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="form-top-row">
            <div className="emoji-product-form-wrapper">
              <button
                type="button"
                className="emoji-button"
                onClick={() => setShowPicker((show) => !show)}
              >
                <span className="emoji-button-selection">{emoji}</span>
                {!emoji && (
                  <img
                    className="emoji-button-icon"
                    src={emojiButton}
                    alt="Smiley Icon"
                  />
                )}
              </button>

              <input
                className="product-form-input"
                type="text"
                id="product"
                placeholder="Produkt"
                required
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                disabled={isUploading}
              />
            </div>
            <div>
              <input
                className="default-form-input"
                type="text"
                id="brand"
                placeholder="Marke"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                disabled={isUploading}
              />
            </div>
          </div>
          <input
            type="hidden"
            id="emoji"
            placeholder="✨ Emoji"
            required
            value={emoji}
            disabled={isUploading}
          />
          <div className="form-top-row">
            <input
              className="default-form-input"
              type="number"
              id="quantity"
              placeholder="Menge"
              pattern="[0-9]*"
              required
              inputMode="decimal"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              disabled={isUploading}
            />
            <input
              className="default-form-input"
              type="text"
              id="unit"
              placeholder="Einheit"
              required
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              disabled={isUploading}
            />
            <select
              className="default-form-input"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              type="text"
              id="category"
              disabled={isUploading}
            >
              {categoryList.map((cat) => (
                <option key={cat.name} value={cat.value}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-top-row">
            <input
              className="submit-button"
              type="submit"
              value="+ Hinzufügen"
              disabled={isUploading}
            />
          </div>
          <hr />
        </form>
      </div>
      {showPicker && (
        <Picker
          data={data}
          onEmojiSelect={(emoji, event) => {
            setEmoji(emoji.native);
          }}
          categories={["foods", "objects", "activity", "people", "nature"]}
          theme={"light"}
          previewPosition={"none"}
          dynamicWidth={true}
          emojiButtonColors={["lightgrey"]}
          navPosition={"bottom"}
          locale={"de"}
          skinTonePosition={"none"}
        />
      )}
    </>
  );
}

function Loader() {
  return <p className="message">Loading ...</p>;
}

function List({
  items,
  fetchError,
  setItemsUpdated,
  checkedItems,
  setCheckedItems,
}) {
  // Show  Message, when Category is Empty
  // if (items.length === 0) {
  //   return <p>No items for this category</p>;
  // }

  return (
    <main>
      {fetchError && <p>{fetchError}</p>}
      {items && (
        <ul className="item-list">
          {items.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              setItemsUpdated={setItemsUpdated}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          ))}
          <p className="message">
            {checkedItems.length} von {items.length} Einträge abehakt.
          </p>
        </ul>
      )}
    </main>
  );
}

function ListItem({ item, setItemsUpdated, checkedItems, setCheckedItems }) {
  // Check if the current item is in the checkedItems array
  const isChecked = checkedItems.includes(item.id);

  const handleCheckboxClick = () => {
    // If the item is already checked, remove it from the checkedItems array
    // Otherwise, add it to the checkedItems array
    if (isChecked) {
      setCheckedItems(checkedItems.filter((itemId) => itemId !== item.id));
    } else {
      setCheckedItems([...checkedItems, item.id]);
    }
  };

  // Function to delete an item
  async function deleteItem(itemId) {
    const { error } = await supabase
      .from("shoppingListTable")
      .delete()
      .eq("id", itemId);

    if (!error) {
      // If there is no error when deleting the new item
      setItemsUpdated((updated) => !updated); // Change the value of itemsUpdated
    }
  }

  return (
    <li id={"item" + item.id} className="list-item">
      <div className={`left-content ${isChecked ? "strikethrough" : ""}`}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
        />
        <span>
          {item.emoji} {item.brand} {item.product} ({item.quantity} {item.unit})
        </span>
      </div>
      <span
        className="delete-button"
        onClick={() => {
          const confirmDelete = window.confirm(
            "Möchtest Du den Eintrag wirklich löschen?"
          );
          if (confirmDelete) {
            deleteItem(item.id);
            setItemsUpdated((prev) => !prev); // toggle itemsUpdated state
          }
        }}
      >
        +
      </span>
    </li>
  );
}

function CategoryFilter({ setCurrentCategory }) {
  return <CategoryButton setCurrentCategory={setCurrentCategory} />;
}

function CategoryButton({ setCurrentCategory }) {
  return (
    <>
      <p>Categories</p>
      <button
        className="category-button"
        onClick={() => setCurrentCategory("all")}
      >
        All
      </button>
      {categoryList.map((cat) => (
        <button
          className="category-button"
          id={cat.value}
          onClick={() => setCurrentCategory(cat.value)}
        >
          {cat.name}
        </button>
      ))}
    </>
  );
}

function DeleteCheckedButton({
  checkedItems,
  setCheckedItems,
  setItemsUpdated,
}) {
  const handleButtonClick = async () => {
    // Zeige Bestätigungsdialog an
    const confirmDelete = window.confirm(
      "Möchtest Du die Einträge wirklich löschen?"
    );

    if (confirmDelete) {
      const { error } = await supabase
        .from("shoppingListTable")
        .delete()
        .in("id", checkedItems);

      if (!error) {
        setCheckedItems([]);
        setItemsUpdated((updated) => !updated);
      }
    }
  };

  // Zeige den Button nur an, wenn mindestens ein Element ausgewählt wurde
  if (checkedItems.length > 0) {
    return (
      <button className="deleteCheckedButton" onClick={handleButtonClick}>
        <span className="delete-cross">+</span> Ausgewählte Einträge löschen
      </button>
    );
  } else {
    // Wenn kein Element ausgewählt wurde, geben wir null zurück, damit nichts gerendert wird
    return null;
  }
}

export default App;
