var categoryList = [
  { value: "", name: "Kategorie" },
  { value: "00_pickAndGo", name: "Pick&Go" },
  { value: "01_smoothiesFertiggerichte", name: "Smoothies & Fertiggerichte" },
  { value: "02_brotBackwaren", name: "Brot & Backwaren" },
  { value: "03_obstGemuese", name: "Obst & Gemüse" },
  { value: "04_sushiSalatbar", name: "Sushi- & Salatbar" },
  { value: "05_veganVegetarisch", name: "Vegan & Vegetarisch" },
  { value: "06_Fisch", name: "Fisch" },
  { value: "07_kaese", name: "Käse" },
  { value: "08_wurst", name: "Wurst" },
  {
    value: "09_cerealienTrockenfruechteNuesse",
    name: "Cerealien, Trockenfrüchte & Nüsse",
  },
  { value: "10_kaffeeTeeKakao", name: "Kaffee, Tee & Kakao" },
  { value: "11_brotaufstriche", name: "Brotaufstriche" },
  { value: "12_eierMilchprodukte", name: "Eier & Milchprodukte" },
  { value: "13_joghurtDesserts", name: "Joghurt & Desserts" },
  { value: "14_pflanzenmilch", name: "Pflanzenmilch" },
  { value: "15_suesseSalzigeSnacks", name: "Süße & Salzige Snacks" },
  { value: "16_feinkostKonserven", name: "Feinkost & Konserven" },
  {
    value: "17_nudelnReisKartoffelprodukte",
    name: "Nudeln, Reis & Kartoffelprodukte",
  },
  { value: "18_oeleSaucenGewuerze", name: "Öle, Saucen & Gewürze" },
  { value: "19_bierWeinSekt", name: "Bier, Wein & Sekt" },
  { value: "20_spirituosen", name: "Spirituosen" },
  { value: "21_tiefkuehl", name: "Tiefkühl" },
  { value: "22_getraenke", name: "Getränke" },
  { value: "23_baby", name: "Baby" },
  { value: "24_tiernahrung", name: "Tiernahrung" },
  { value: "25_hygieneKosmetik", name: "Hygiene & Kosmetik" },
  { value: "26_putzmittel", name: "Putzmittel" },
  { value: "27_haushaltTechnik", name: "Haushalt & Technik" },
  { value: "28_fitness", name: "Fitness" },
  { value: "29_sonstiges", name: "Sonstiges" },
];

export var categoryList;
